import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { getFormValues } from 'redux-form';
import { isEmpty, isObject, omit } from 'lodash';
import { withRouter } from 'react-router-dom';
import { ButtonGroup, Dropdown } from 'react-bootstrap';
import { toastr } from 'react-redux-toastr';
import queryString from 'query-string';

import ProjectFinalizeForm, { FORM_ID as FINALIZE_FORM_ID } from 'projects/assets/js/components/ProjectFinalizeForm.jsx';
import TDDropButton from 'core/assets/js/components/TDDropButton.jsx';
import TDButton from 'core/assets/js/components/TDButton.jsx';
import ModalConfirm from 'core/assets/js/components/ModalConfirm.jsx';
import ProjectCloneForm, { FORM_ID as CLONE_PROJECT_FORM_ID } from 'projects/assets/js/components/ProjectCloneForm.jsx';
import TaskCloneComponentsSelection, { FORM_ID as CLONE_TASK_FORM_ID } from 'projects/assets/js/components/TaskCloneComponentsSelection.jsx';
import { pluralize } from 'core/assets/js/lib/utils';
import { PEOPLE_TYPE } from 'people/assets/js/constants';
import { MODAL_ID as ADD_MEMBERS_TO_PROJECT_MODAL_ID } from 'projects/assets/js/components/ProjectAddMembersPanel.jsx';
import { routerHistorySpec, routerMatchSpec } from 'core/assets/js/lib/objectSpecs';
import { ICON, BS_STYLE, PROJECT_TABS, TASK_TABS } from 'core/assets/js/constants';
import { modalOpenAC } from 'core/assets/js/ducks/modalLauncher';
import { MODAL_ID as PROJECT_OWNERSHIP_MODAL_ID } from 'projects/assets/js/components/ProjectOwnerModal.jsx';
import { MODAL_ID as TASK_OWNERSHIP_MODAL_ID } from 'projects/assets/js/components/TaskOwnerModal.jsx';
import { MODAL_ID as RETURN_FUNDS_MODAL_ID } from 'projects/assets/js/components/ProjectReturnFundsModal.jsx';
import PendingTaskAssignments from 'projects/assets/js/components/PendingTaskAssignments.jsx';
import TaskRaiseWorksheetsPanel, { MODAL_ID as TASK_RAISE_WORKSHEETS_MODAL_ID } from 'projects/assets/js/components/TaskRaiseWorksheetsPanel.jsx';
import { financeWorksheetsUrl, financeExpensesUrl, financePurchaseOrderListUrl } from 'finance/urls';
import {
  cloneProjectDS, projectArchiveDS, projectUnarchiveDS, updateProjectStateDS,
} from 'projects/assets/js/data-services/form';
import {
  approveTaskDS, cloneTaskDS, projectArchiveTaskDS, projectUnarchiveTaskDS,
} from 'projects/assets/js/data-services/tasks';
import { selectActiveOrg, selectActiveUserCard } from 'organizations/assets/js/reducers/organizations';
import {
  BLOCKING_TASK_ACTIONS,
  PROJECT_COMPLETION_FUNDS,
  PROJECT_TASKS_STATUS_CHANGE,
  PROJECT_STATE_CHANGE,
  PROJECT_STATUS,
  PROJECT_WORKSHEET_IMPORT_STEP,
  PURCHASE_ORDER_STATUS,
  SERVICE_ORDER_PENDING_STATUSES,
  TASK_ACTION,
  TASK_ASSIGNMENT_STATUS,
  TASK_STATUS,
} from 'projects/assets/js/constants';
import {
  projectClaimExpenseUrl,
  projectCreateTaskUrl,
  projectEditTaskUrl,
  projectEditUrl,
  projectRequestBudgetUrl,
  projectSubmitWorksheetUrl,
  projectListUrl,
  projectViewUrl,
  projectViewTaskUrl,
  projectWorksheetImportUrl,
} from 'projects/urls';

import {
  expenseAllowedActionsSpec,
  projectAllowedActionsSpec,
  projectSpec,
  taskInfoSpec,
  projectTaskSpec,
  taskAllowedActionsSpec,
  taskAssignmentSpec,
  worksheetAllowedActionsSpec,
} from 'projects/assets/js/lib/objectSpecs';
import { orgSpec, userCardSpec } from 'organizations/assets/js/lib/objectSpecs';
import { DOCUMENT_GET_ELEMENT_BY_ID, DISPATCH_EVENT_ON_ELEMENT } from 'core/assets/js/config/settings';
import ProjectCompleteModal, { MODAL_ID as COMPLETE_MODAL_ID } from 'projects/assets/js/components/ProjectCompleteModal.jsx';
import ProjectReopenModal, { MODAL_ID as REOPEN_MODAL_ID } from 'projects/assets/js/components/ProjectReopenModal.jsx';
import { MODAL_ID as ADD_MANAGERS_TO_TASK_MODAL_ID } from 'projects/assets/js/components/TaskAddManagersPanel.jsx';

export const ACTION = {
  ADD_MANAGERS: 'ADD_MANAGERS',
  ADD_TASK_MANAGERS: 'ADD_TASK_MANAGERS',
  ADD_PROVIDERS: 'ADD_PROVIDERS',
  APPROVE_TASK: 'APPROVE_TASK',
  ARCHIVE_PROJECT: 'ARCHIVE_PROJECT',
  ARCHIVE_TASK: 'ARCHIVE_TASK',
  ASSIGN_TASK: 'ASSIGN_TASK',
  CLAIM_EXPENSE: 'CLAIM_EXPENSE',
  CLONE_PROJECT: 'CLONE_PROJECT',
  CLONE_TASK: 'CLONE_TASK',
  COMPLETE_TASK: 'COMPLETE_TASK',
  COMPLETE_TASK_ASSIGNMENT: 'COMPLETE_TASK_ASSIGNMENT',
  CREATE_TASK: 'CREATE_TASK',
  CREATE_WORKSHEET: 'CREATE_WORKSHEET',
  EDIT_PROJECT: 'EDIT_PROJECT',
  EDIT_TASK: 'EDIT_TASK',
  LEAVE_TASK: 'LEAVE_TASK',
  MARK_AS_COMPLETED: 'MARK_AS_COMPLETED',
  NOOP: 'NOOP',
  RAISE_WORKSHEET_FOR_TASK: 'RAISE_WORKSHEET_FOR_TASK',
  RAISE_WORKSHEETS_FOR_TASK: 'RAISE_WORKSHEETS_FOR_TASK',
  REOPEN_PROJECT: 'REOPEN_PROJECT',
  REQUEST_BUDGET: 'REQUEST_BUDGET',
  REQUEST_COMPLETION: 'REQUEST_COMPLETION',
  RETURN_FUNDS: 'RETURN_FUNDS',
  START_PROJECT: 'START_PROJECT',
  STOP_TASK: 'STOP_TASK',
  TRANSFER_PROJECT_OWNERSHIP: 'TRANSFER_PROJECT_OWNERSHIP',
  TRANSFER_TASK_OWNERSHIP: 'TRANSFER_TASK_OWNERSHIP',
  UNARCHIVE_PROJECT: 'UNARCHIVE_PROJECT',
  UNARCHIVE_TASK: 'UNARCHIVE_TASK',
  VIEW_WORKSHEETS: 'VIEW_WORKSHEETS',
  PROPOSE_TASK_RATE: 'PROPOSE_TASK_RATE',
  IMPORT_WORKSHEETS: 'IMPORT_WORKSHEETS',
};

export const BUTTON_LABELS = {
  [ACTION.ADD_MANAGERS]: 'Add managers',
  [ACTION.ADD_PROVIDERS]: 'Add providers',
  [ACTION.ADD_TASK_MANAGERS]: 'Add managers',
  [ACTION.APPROVE_TASK]: 'Approve and complete',
  [ACTION.ARCHIVE_PROJECT]: 'Archive project',
  [ACTION.ARCHIVE_TASK]: 'Archive task',
  [ACTION.ASSIGN_TASK]: 'Add providers',
  [ACTION.CLAIM_EXPENSE]: 'Claim expense',
  [ACTION.CLONE_PROJECT]: 'Clone project',
  [ACTION.CLONE_TASK]: 'Clone task',
  [ACTION.COMPLETE_TASK]: 'Complete task',
  [ACTION.COMPLETE_TASK_ASSIGNMENT]: 'Complete assignment',
  [ACTION.CREATE_TASK]: 'Create task',
  [ACTION.CREATE_WORKSHEET]: 'Submit worksheet',
  [ACTION.EDIT_PROJECT]: 'Edit project',
  [ACTION.EDIT_TASK]: 'Edit task',
  [ACTION.LEAVE_TASK]: 'Leave task',
  [ACTION.MARK_AS_COMPLETED]: 'Mark as completed',
  [ACTION.NOOP]: 'All done!',
  [ACTION.RAISE_WORKSHEET_FOR_TASK]: 'Submit worksheet',
  [ACTION.RAISE_WORKSHEETS_FOR_TASK]: 'Submit worksheets',
  [ACTION.REQUEST_BUDGET]: 'Request budget',
  [ACTION.REQUEST_COMPLETION]: 'Request Task completion',
  [ACTION.REOPEN_PROJECT]: 'Re-open project',
  [ACTION.RETURN_FUNDS]: 'Return funds',
  [ACTION.START_PROJECT]: 'Start project',
  [ACTION.STOP_TASK]: 'Stop task',
  [ACTION.TRANSFER_PROJECT_OWNERSHIP]: 'Transfer project ownership',
  [ACTION.TRANSFER_TASK_OWNERSHIP]: 'Transfer task ownership',
  [ACTION.UNARCHIVE_PROJECT]: 'Unarchive project',
  [ACTION.UNARCHIVE_TASK]: 'Unarchive task',
  [ACTION.VIEW_WORKSHEETS]: 'View worksheets',
  [ACTION.LEAVE_TASK]: 'Leave task',
  [ACTION.PROPOSE_TASK_RATE]: 'Adjust agreed rate',
  [ACTION.IMPORT_WORKSHEETS]: 'Import worksheets',
};

export const DISABLED_BUTTON_STATES = [
  ACTION.NOOP,
];

export const DISABLED_MESSAGES = {
  [ACTION.NOOP]: 'The project completed successfully!',
};

export const TASK_DISCUSSION_BOARD_ACTIONS = [
  ACTION.REQUEST_COMPLETION,
  ACTION.COMPLETE_TASK,
  ACTION.COMPLETE_TASK_ASSIGNMENT,
  ACTION.STOP_TASK,
  ACTION.LEAVE_TASK,
  ACTION.PROPOSE_TASK_RATE,
];

const CTA_ACTION_TO_DISCUSSION_BOARD_ACTION = {
  [ACTION.REQUEST_COMPLETION]: TASK_ACTION.REQUEST_COMPLETION,
  [ACTION.COMPLETE_TASK]: TASK_ACTION.COMPLETE,
  [ACTION.COMPLETE_TASK_ASSIGNMENT]: TASK_ACTION.COMPLETE_ASSIGNMENT,
  [ACTION.STOP_TASK]: TASK_ACTION.STOP,
  [ACTION.LEAVE_TASK]: TASK_ACTION.LEAVE,
  [ACTION.ASSIGN_TASK]: TASK_ACTION.ASSIGN,
  [ACTION.PROPOSE_TASK_RATE]: TASK_ACTION.PROPOSE_TASK_RATE,
};

const CTA_ACTION_TO_CSS_CLASS = {
  [ACTION.STOP_TASK]: `text-${BS_STYLE.DANGER}`,
  [ACTION.ARCHIVE_TASK]: `text-${BS_STYLE.DANGER}`,
  [ACTION.ARCHIVE_PROJECT]: `text-${BS_STYLE.DANGER}`,
  [ACTION.LEAVE_TASK]: `text-${BS_STYLE.DANGER}`,
};

export const PendingDocumentsLabel = ({ count, label, prefix }) => {
  if (count === 1) {
    return (
      <React.Fragment>
        {prefix}
        {' '}
        is
        {' '}
        <strong>{count}</strong>
        {' '}
        {label}
      </React.Fragment>
    );
  }

  return (
    <React.Fragment>
      {prefix}
      {' '}
      are
      {' '}
      <strong>{count}</strong>
      {' '}
      { pluralize(label, count) }
    </React.Fragment>
  );
};

PendingDocumentsLabel.propTypes = {
  count: PropTypes.number.isRequired,
  label: PropTypes.string.isRequired,
  prefix: PropTypes.string.isRequired,
};

// eslint-disable-next-line react/no-multi-comp
class ProjectCta extends React.Component {
  constructor(props) {
    super(props);
    this.defaultModalState = {
      open: false,
      heading: '-',
      body: '-',
      confirmLabel: 'Ok',
      confirmStyle: 'primary',
    };

    this.state = {
      modal: this.defaultModalState,
    };

    this.addManagersFn = this.addManagersFn.bind(this);
    this.addProvidersFn = this.addProvidersFn.bind(this);
    this.addTaskManagersFn = this.addTaskManagersFn.bind(this);
    this.archiveProjectFn = this.archiveProjectFn.bind(this);
    this.archiveTaskFn = this.archiveTaskFn.bind(this);
    this.claimExpenseFn = this.claimExpenseFn.bind(this);
    this.createTaskFn = this.createTaskFn.bind(this);
    this.editProjectFn = this.editProjectFn.bind(this);
    this.editTaskFn = this.editTaskFn.bind(this);
    this.getAssignmentsWithoutWorksheets = this.getAssignmentsWithoutWorksheets.bind(this);
    this.handleArchiveProject = this.handleArchiveProject.bind(this);
    this.handleCloneTask = this.handleCloneTask.bind(this);
    this.handleCloseModal = this.handleCloseModal.bind(this);
    this.handleConfirmModal = this.handleConfirmModal.bind(this);
    this.handleOpenModal = this.handleOpenModal.bind(this);
    this.handleProjectCloneFn = this.handleProjectCloneFn.bind(this);
    this.handleProjectStateChange = this.handleProjectStateChange.bind(this);
    this.handleRaiseWorksheetForTaskFn = this.handleRaiseWorksheetForTaskFn.bind(this);
    this.handleRaiseWorksheetsForTaskFn = this.handleRaiseWorksheetsForTaskFn.bind(this);
    this.handleRestoreState = this.handleRestoreState.bind(this);
    this.handleTaskAddProvidersFn = this.handleTaskAddProvidersFn.bind(this);
    this.handleTaskAssignmentCompletionFn = this.handleTaskAssignmentCompletionFn.bind(this);
    this.handleTaskCompletionFn = this.handleTaskCompletionFn.bind(this);
    this.handleTaskCompletionRequestFn = this.handleTaskCompletionRequestFn.bind(this);
    this.handleTaskCloneFn = this.handleTaskCloneFn.bind(this);
    this.handleTaskStopFn = this.handleTaskStopFn.bind(this);
    this.handleUnarchiveProject = this.handleUnarchiveProject.bind(this);
    this.handleViewWorksheetsFn = this.handleViewWorksheetsFn.bind(this);
    this.leaveTaskFn = this.leaveTaskFn.bind(this);
    this.markProjectAsCompletedFn = this.markProjectAsCompletedFn.bind(this);
    this.raiseWorksheetFn = this.raiseWorksheetFn.bind(this);
    this.reopenProjectFn = this.reopenProjectFn.bind(this);
    this.requestBudgetFn = this.requestBudgetFn.bind(this);
    this.returnFundsFn = this.returnFundsFn.bind(this);
    this.startProjectFn = this.startProjectFn.bind(this);
    this.transferProjectOwnershipFn = this.transferProjectOwnershipFn.bind(this);
    this.transferTaskOwnershipFn = this.transferTaskOwnershipFn.bind(this);
    this.unarchiveTaskFn = this.unarchiveTaskFn.bind(this);
    this.archiveProjectFn = this.archiveProjectFn.bind(this);
    this.leaveTaskFn = this.leaveTaskFn.bind(this);
    this.handleProposeTaskRate = this.handleProposeTaskRate.bind(this);
    this.importWorksheets = this.importWorksheets.bind(this);
    this.approveTaskFn = this.approveTaskFn.bind(this);
    this.approveTaskModalOpenFn = this.approveTaskModalOpenFn.bind(this);
    this.noop = () => {};

    this.actionCallbacks = {
      [ACTION.ADD_MANAGERS]: this.addManagersFn,
      [ACTION.ADD_PROVIDERS]: this.addProvidersFn,
      [ACTION.ADD_TASK_MANAGERS]: this.addTaskManagersFn,
      [ACTION.APPROVE_TASK]: this.approveTaskModalOpenFn,
      [ACTION.ARCHIVE_PROJECT]: this.archiveProjectFn,
      [ACTION.ARCHIVE_TASK]: this.archiveTaskFn,
      [ACTION.ASSIGN_TASK]: this.handleTaskAddProvidersFn,
      [ACTION.CLAIM_EXPENSE]: this.claimExpenseFn,
      [ACTION.CLONE_PROJECT]: this.handleProjectCloneFn,
      [ACTION.CLONE_TASK]: this.handleTaskCloneFn,
      [ACTION.COMPLETE_TASK]: this.handleTaskCompletionFn,
      [ACTION.COMPLETE_TASK_ASSIGNMENT]: this.handleTaskAssignmentCompletionFn,
      [ACTION.CREATE_TASK]: this.createTaskFn,
      [ACTION.CREATE_WORKSHEET]: this.raiseWorksheetFn,
      [ACTION.EDIT_PROJECT]: this.editProjectFn,
      [ACTION.EDIT_TASK]: this.editTaskFn,
      [ACTION.LEAVE_TASK]: this.leaveTaskFn,
      [ACTION.MARK_AS_COMPLETED]: this.markProjectAsCompletedFn,
      [ACTION.NOOP]: this.noop,
      [ACTION.RAISE_WORKSHEET_FOR_TASK]: this.handleRaiseWorksheetForTaskFn,
      [ACTION.RAISE_WORKSHEETS_FOR_TASK]: this.handleRaiseWorksheetsForTaskFn,
      [ACTION.REQUEST_BUDGET]: this.requestBudgetFn,
      [ACTION.REQUEST_COMPLETION]: this.handleTaskCompletionRequestFn,
      [ACTION.REOPEN_PROJECT]: this.reopenProjectFn,
      [ACTION.RETURN_FUNDS]: this.returnFundsFn,
      [ACTION.START_PROJECT]: this.startProjectFn,
      [ACTION.STOP_TASK]: this.handleTaskStopFn,
      [ACTION.TRANSFER_PROJECT_OWNERSHIP]: this.transferProjectOwnershipFn,
      [ACTION.TRANSFER_TASK_OWNERSHIP]: this.transferTaskOwnershipFn,
      [ACTION.UNARCHIVE_PROJECT]: this.handleUnarchiveProject,
      [ACTION.UNARCHIVE_TASK]: this.unarchiveTaskFn,
      [ACTION.VIEW_WORKSHEETS]: this.handleViewWorksheetsFn,
      [ACTION.LEAVE_TASK]: this.leaveTaskFn,
      [ACTION.ASSIGN_TASK]: this.handleTaskAddProvidersFn,
      [ACTION.PROPOSE_TASK_RATE]: this.handleProposeTaskRate,
      [ACTION.IMPORT_WORKSHEETS]: this.importWorksheets,
    };

    this.getAvailableActions = this.getAvailableActions.bind(this);
    this.getDiscussionBoardAction = this.getDiscussionBoardAction.bind(this);
    this.getPendingDocumentObjections = this.getPendingDocumentObjections.bind(this);
    this.getPendingTaskObjections = this.getPendingTaskObjections.bind(this);
    this.handleTaskPendingActions = this.handleTaskPendingActions.bind(this);
  }

  getAvailableActions() {
    const {
      activeUserCard,
      currentTab,
      expenseAllowedActions,
      organization,
      participantUserId,
      project,
      projectAllowedActions,
      task,
      taskAllowedActions,
      taskAssignments,
      worksheetAllowedActions,
    } = this.props;
    const { task_managers_enabled: taskManagersEnabled } = organization;

    const actions = { primary: [], secondary: [] };
    if (!project || !project.status || !projectAllowedActions) {
      return actions;
    }

    const allStatusActions = [
      [ACTION.CLONE_PROJECT, () => ({
        enabled: projectAllowedActions.canClone,
        primary: currentTab === PROJECT_TABS.DASHBOARD,
      })],
    ];

    const actionApproveTask = [ACTION.APPROVE_TASK, () => ({
      enabled: !isEmpty(task) && taskAllowedActions.canApproveTask,
      primary: currentTab === PROJECT_TABS.TASKS && taskAllowedActions.canApproveTask,
    })];

    const actionAddProviders = [ACTION.ADD_PROVIDERS, () => ({
      primary: [PROJECT_TABS.TEAM, PROJECT_TABS.APPLICATIONS].includes(currentTab),
      enabled: projectAllowedActions.canAddProviders,
    })];

    const actionsPool = {
      [PROJECT_STATUS.COMPLETED]: [
        actionApproveTask,
        [ACTION.CLONE_TASK, () => ({
          primary: currentTab === PROJECT_TABS.TASKS,
          enabled: !isEmpty(task) && taskAllowedActions.canCloneTask,
        })],
        [ACTION.RETURN_FUNDS, () => ({
          primary: currentTab === PROJECT_TABS.PURCHASE_ORDERS,
          enabled: projectAllowedActions.canReturnFunds,
        })],
        [ACTION.NOOP, () => ({
          enabled: !!projectAllowedActions.isOwner && currentTab !== PROJECT_TABS.PURCHASE_ORDERS,
          primary: currentTab !== PROJECT_TABS.PURCHASE_ORDERS,
        })],
        [ACTION.REQUEST_BUDGET, () => ({
          primary: currentTab === PROJECT_TABS.PURCHASE_ORDERS,
          enabled: projectAllowedActions.canRequestBudget,
        })],
        [ACTION.CREATE_WORKSHEET, () => ({
          primary: currentTab === PROJECT_TABS.WORKSHEETS,
          enabled: worksheetAllowedActions.canCreate
            && worksheetAllowedActions.shouldCreateForCurrentPeriod,
        })],
        [ACTION.ARCHIVE_PROJECT, () => ({
          primary: currentTab === PROJECT_TABS.DASHBOARD,
          enabled: projectAllowedActions.canArchiveProject,
        })],
        [ACTION.UNARCHIVE_PROJECT, () => ({
          primary: currentTab === PROJECT_TABS.DASHBOARD,
          enabled: projectAllowedActions.canUnarchiveProject,
        })],
        [ACTION.REOPEN_PROJECT, () => ({
          primary: currentTab === PROJECT_TABS.DASHBOARD,
          enabled: projectAllowedActions.canReopenProject,
        })],
        ...allStatusActions,
      ],
      [PROJECT_STATUS.POSTED]: [
        [ACTION.START_PROJECT, () => ({
          enabled: projectAllowedActions.canStartProject,
          primary: currentTab === PROJECT_TABS.DASHBOARD,
        })],
        actionAddProviders,
        [ACTION.ADD_MANAGERS, () => ({
          primary: currentTab === PROJECT_TABS.TEAM,
          enabled: projectAllowedActions.canAddManagers,
        })],
        [ACTION.REQUEST_BUDGET, () => ({
          primary: currentTab === PROJECT_TABS.PURCHASE_ORDERS,
          enabled: projectAllowedActions.canRequestBudget,
        })],
        [ACTION.RETURN_FUNDS, () => ({
          primary: currentTab === PROJECT_TABS.PURCHASE_ORDERS,
          enabled: projectAllowedActions.canReturnFunds,
        })],
        [ACTION.EDIT_PROJECT, () => ({
          enabled: projectAllowedActions.canEditProject,
          primary: currentTab === PROJECT_TABS.DASHBOARD,
        })],
        [ACTION.TRANSFER_PROJECT_OWNERSHIP, () => ({
          enabled: projectAllowedActions.canTransferOwnership,
          primary: currentTab === PROJECT_TABS.DASHBOARD,
        })],
        [ACTION.CREATE_TASK, () => ({
          primary: currentTab === PROJECT_TABS.TASKS,
          enabled: isEmpty(task) && projectAllowedActions.canCreateTask,
        })],
        [ACTION.EDIT_TASK, () => ({
          primary: currentTab === PROJECT_TABS.TASKS,
          enabled: !isEmpty(task) && taskAllowedActions.canEditTask?.value,
        })],
        [ACTION.CLONE_TASK, () => ({
          primary: currentTab === PROJECT_TABS.TASKS,
          enabled: !isEmpty(task) && taskAllowedActions.canCloneTask,
        })],
        [ACTION.ADD_TASK_MANAGERS, () => ({
          primary: currentTab === PROJECT_TABS.TASKS,
          enabled: taskManagersEnabled
            && !isEmpty(task) && taskAllowedActions.canManageTaskManagers,
        })],
        [ACTION.TRANSFER_TASK_OWNERSHIP, () => ({
          primary: currentTab === PROJECT_TABS.TASKS,
          enabled: !isEmpty(task) && taskAllowedActions.canTransferOwnership,
        })],
        [ACTION.ARCHIVE_TASK, () => ({
          primary: currentTab === PROJECT_TABS.TASKS,
          enabled: !isEmpty(task) && taskAllowedActions.canArchiveTask,
        })],
        [ACTION.UNARCHIVE_TASK, () => ({
          primary: currentTab === PROJECT_TABS.TASKS,
          enabled: !isEmpty(task) && taskAllowedActions.canUnarchiveTask,
        })],
        [ACTION.ARCHIVE_PROJECT, () => ({
          primary: currentTab === PROJECT_TABS.DASHBOARD,
          enabled: projectAllowedActions.canArchiveProject,
        })],
        [ACTION.UNARCHIVE_PROJECT, () => ({
          primary: currentTab === PROJECT_TABS.DASHBOARD,
          enabled: projectAllowedActions.canUnarchiveProject,
        })],
        [ACTION.LEAVE_TASK, () => ({
          primary: currentTab === PROJECT_TABS.TASKS,
          enabled: !isEmpty(task) && taskAllowedActions.canLeaveTask,
        })],
        ...allStatusActions,
      ],
      [PROJECT_STATUS.INPROGRESS]: [
        actionApproveTask,
        [ACTION.IMPORT_WORKSHEETS, () => ({
          primary: currentTab === PROJECT_TABS.DASHBOARD,
          enabled: projectAllowedActions.canImportWorksheets,
        })],
        [ACTION.MARK_AS_COMPLETED, () => ({
          primary: currentTab === PROJECT_TABS.DASHBOARD,
          enabled: projectAllowedActions.canMarkAsCompleted,
        })],
        [ACTION.EDIT_PROJECT, () => ({
          primary: currentTab === PROJECT_TABS.DASHBOARD,
          enabled: projectAllowedActions.canEditProject,
        })],
        [ACTION.TRANSFER_PROJECT_OWNERSHIP, () => ({
          primary: currentTab === PROJECT_TABS.DASHBOARD,
          enabled: projectAllowedActions.canTransferOwnership,
        })],
        actionAddProviders,
        [ACTION.ADD_MANAGERS, () => ({
          primary: currentTab === PROJECT_TABS.TEAM,
          enabled: projectAllowedActions.canAddManagers,
        })],
        [ACTION.CREATE_WORKSHEET, () => ({
          primary: currentTab === PROJECT_TABS.WORKSHEETS,
          enabled: worksheetAllowedActions.canCreate
            && worksheetAllowedActions.shouldCreateForCurrentPeriod,
        })],
        [ACTION.CLAIM_EXPENSE, () => ({
          primary: currentTab === PROJECT_TABS.EXPENSES,
          enabled: expenseAllowedActions.canClaim,
        })],
        [ACTION.REQUEST_BUDGET, () => ({
          primary: currentTab === PROJECT_TABS.PURCHASE_ORDERS,
          enabled: projectAllowedActions.canRequestBudget,
        })],
        [ACTION.RETURN_FUNDS, () => ({
          primary: currentTab === PROJECT_TABS.PURCHASE_ORDERS,
          enabled: projectAllowedActions.canReturnFunds,
        })],
        [ACTION.CREATE_TASK, () => ({
          primary: currentTab === PROJECT_TABS.TASKS,
          enabled: isEmpty(task) && projectAllowedActions.canCreateTask,
        })],
        [ACTION.COMPLETE_TASK_ASSIGNMENT, () => ({
          primary: currentTab === PROJECT_TABS.TASKS && !!participantUserId,
          enabled: !isEmpty(task) && this.getDiscussionBoardAction(TASK_ACTION.COMPLETE_ASSIGNMENT),
        })],
        [ACTION.COMPLETE_TASK, () => ({
          primary: currentTab === PROJECT_TABS.TASKS,
          enabled: !isEmpty(task) && taskAllowedActions.canCompleteTask,
        })],
        [ACTION.REQUEST_COMPLETION, () => ({
          primary: currentTab === PROJECT_TABS.TASKS,
          enabled: !isEmpty(task) && taskAllowedActions.canRequestCompletion,
        })],
        [ACTION.ASSIGN_TASK, () => ({
          primary: currentTab === PROJECT_TABS.TASKS,
          enabled: !isEmpty(task) && taskAllowedActions.canAssignTask,
        })],
        [ACTION.EDIT_TASK, () => ({
          primary: currentTab === PROJECT_TABS.TASKS,
          enabled: !isEmpty(task) && taskAllowedActions.canEditTask?.value,
        })],
        [ACTION.CLONE_TASK, () => ({
          primary: currentTab === PROJECT_TABS.TASKS,
          enabled: !isEmpty(task) && taskAllowedActions.canCloneTask,
        })],
        [ACTION.ADD_TASK_MANAGERS, () => ({
          primary: currentTab === PROJECT_TABS.TASKS,
          enabled: taskManagersEnabled
            && !isEmpty(task) && taskAllowedActions.canManageTaskManagers,
        })],
        [ACTION.TRANSFER_TASK_OWNERSHIP, () => ({
          primary: currentTab === PROJECT_TABS.TASKS,
          enabled: !isEmpty(task) && taskAllowedActions.canTransferOwnership,
        })],
        [ACTION.STOP_TASK, () => ({
          primary: currentTab === PROJECT_TABS.TASKS,
          enabled: !isEmpty(task) && taskAllowedActions.canStopTask,
        })],
        [ACTION.ARCHIVE_TASK, () => ({
          primary: currentTab === PROJECT_TABS.TASKS,
          enabled: !isEmpty(task) && taskAllowedActions.canArchiveTask,
        })],
        [ACTION.UNARCHIVE_TASK, () => ({
          primary: currentTab === PROJECT_TABS.TASKS,
          enabled: !isEmpty(task) && taskAllowedActions.canUnarchiveTask,
        })],
        [ACTION.LEAVE_TASK, () => ({
          primary: currentTab === PROJECT_TABS.TASKS,
          enabled: !isEmpty(task) && taskAllowedActions.canLeaveTask,
        })],
        [ACTION.PROPOSE_TASK_RATE, () => ({
          primary: currentTab === PROJECT_TABS.TASKS && !!participantUserId,
          enabled: (
            // Show it only if task has already been started
            !isEmpty(task)
              && this.getDiscussionBoardAction(TASK_ACTION.PROPOSE_TASK_RATE)
              && taskAssignments?.[0]?.status !== TASK_ASSIGNMENT_STATUS.PENDING
          ),
        })],
        [ACTION.RAISE_WORKSHEET_FOR_TASK, () => ({
          primary: currentTab === PROJECT_TABS.TASKS,
          enabled: (
            !isEmpty(task)
            && !!task.worksheetAccessControl
            && activeUserCard?.userRole?.isProvider
          ),
        })],
        [ACTION.RAISE_WORKSHEETS_FOR_TASK, () => ({
          primary: currentTab === PROJECT_TABS.TASKS,
          enabled: (
            !isEmpty(task)
            && taskAllowedActions.canRaiseWorksheetsForProvidersAfterCompleting
            && this.getAssignmentsWithoutWorksheets().length > 0
          ),
        })],
        [ACTION.VIEW_WORKSHEETS, () => ({
          primary: currentTab === PROJECT_TABS.TASKS,
          enabled: (
            !isEmpty(task)
            && !!task.hasWorksheetForTask
            && activeUserCard?.userRole?.isProvider
          ),
        })],
        ...allStatusActions,
      ],
    };

    actionsPool[project.status].forEach((tuple) => {
      const [action, callback] = tuple;

      if (typeof callback !== 'function') {
        return;
      }

      const options = callback();

      if (!isObject(options) || !options.enabled) {
        return;
      }

      const target = options.primary ? actions.primary : actions.secondary;
      target.push(action);
    });

    return actions;
  }

  /**
   * Returns the modal properties that should be present when the task has pending
   * actions such as no deliverables, progress not set to 100% etc.
   *
   * @param {Object|Null} appendToBody
   * @returns {Object}
   */
  getPendingTaskObjections(appendToBody) {
    const { task, taskCounts } = this.props;

    const hasAllItemsCompleted = task.items.every(item => !!item.completed);
    const hasProgressFilled = +task.progress === 100;
    const hasDeliverables = taskCounts.deliverablesCount > 0;

    let body;
    const hasPending = !(hasAllItemsCompleted && hasProgressFilled && hasDeliverables);

    if (hasPending) {
      body = (
        <div
          className="task-modal-completion-request"
          data-testid="task-modal-completion-pending-actions"
        >
          <p>
            You tried to mark the task as completed but we noticed that some of the task
            completion requirements listed below are not satisfied:
          </p>
          <div className="mb-3">
            <ul className="ml-4 pl-4">
              {!hasProgressFilled && (
                <li>The task progress is not set to 100%</li>
              )}
              {!hasAllItemsCompleted && (
                <li>Not all items in the Checklist are marked as completed</li>
              )}
              {!hasDeliverables && (
                <li>There are no deliverables uploaded</li>
              )}
            </ul>
          </div>
          <p>
            Are you sure you want to complete the task?
          </p>
          {appendToBody}
        </div>
      );
    }

    return { hasPending, body };
  }

  /**
   * Checks for pending documents and returns the corresponding modal components
   *
   * @param {String} projectStatusLabel The status label for the project
   * @returns {Object}
   */
  getPendingDocumentObjections(projectStatusLabel = 'completed') {
    let hasPending = false;
    let body;
    let heading;
    let payload;
    let confirmLabel;

    const {
      project: { reference },
      match: { params: { orgAlias, id: projectId } },
      pendingWorksheets, pendingExpenses, pendingPurchaseOrders, pendingApplications,
    } = this.props;
    const queryData = { kw: reference };

    if (pendingWorksheets) {
      hasPending = true;
      heading = 'Manage pending worksheets';
      confirmLabel = 'Manage Worksheets';
      queryData.status = SERVICE_ORDER_PENDING_STATUSES;
      payload = {
        redirectUrl: `${financeWorksheetsUrl(orgAlias)}?${queryString.stringify(queryData)}`,
      };
      body = (
        <p>
          <PendingDocumentsLabel
            count={pendingWorksheets}
            label="pending Worksheet"
            prefix={`The project cannot be ${projectStatusLabel} because there`}
          />
          {' '}
          {pendingWorksheets === 1 ? 'that needs' : 'that need'}
          {' '}
          to be approved or rejected before the project can be
          {' '}
          {projectStatusLabel}
          .
        </p>
      );
    } else if (pendingExpenses) {
      hasPending = true;
      heading = 'Manage pending Expense claims';
      confirmLabel = 'Manage Expenses';
      queryData.status = SERVICE_ORDER_PENDING_STATUSES;
      payload = {
        redirectUrl: `${financeExpensesUrl(orgAlias)}?${queryString.stringify(queryData)}`,
      };
      body = (
        <p>
          <PendingDocumentsLabel
            count={pendingExpenses}
            label="pending Expense claim"
            prefix={`The project cannot be ${projectStatusLabel} because there`}
          />
          {' '}
          {pendingExpenses === 1 ? 'that needs' : 'that need'}
          {' '}
          to be approved or rejected before the project can be
          {' '}
          {projectStatusLabel}
          .
        </p>
      );
    } else if (pendingPurchaseOrders) {
      hasPending = true;
      heading = 'View budget requests';
      confirmLabel = 'View budget requests';
      queryData.status = PURCHASE_ORDER_STATUS.PENDING;
      const url = financePurchaseOrderListUrl(orgAlias);
      payload = {
        redirectUrl: `${url}?${queryString.stringify(queryData)}`,
      };

      body = (
        <p>
          <PendingDocumentsLabel
            count={pendingPurchaseOrders}
            label="pending budget request"
            prefix="There"
          />
          {' '}
          {pendingExpenses === 1 ? 'that needs' : 'that need'}
          {' '}
          to be approved or rejected before the project can be
          {' '}
          {projectStatusLabel}
          .
        </p>
      );
    } else if (pendingApplications) {
      hasPending = true;
      heading = 'View Applicants';
      confirmLabel = 'View Applicants';
      payload = { redirectUrl: projectViewUrl(orgAlias, projectId, PROJECT_TABS.APPLICATIONS) };

      body = (
        <p>
          <PendingDocumentsLabel
            count={pendingApplications}
            label="pending Applicant"
            prefix="There"
          />
          {' '}
          {pendingApplications === 1 ? 'that needs' : 'that need'}
          {' '}
          to be accepted or rejected before the project can be
          {' '}
          {projectStatusLabel}
          .
        </p>
      );
    }

    return { hasPending, body, payload, heading, confirmLabel };
  }

  /**
   * @param {String} action the action to look up
   * @returns {Object}
   */
  getDiscussionBoardAction(action) {
    const { taskActions, participantUserId } = this.props;
    return taskActions.find(
      act => act.name === action && act.targetUserId === (participantUserId || null),
    );
  }

  getAssignmentsWithoutWorksheets() {
    const { organization, taskAssignments } = this.props;

    if (!organization.create_provider_worksheets) {
      return [];
    }

    return taskAssignments.filter(ta => (
      !ta.hasWorksheetForTask
      && [
        TASK_ASSIGNMENT_STATUS.ACCEPTED,
        TASK_ASSIGNMENT_STATUS.COMPLETED,
        TASK_ASSIGNMENT_STATUS.PENDING,
      ].includes(ta.status)
    ));
  }

  /**
   * Open the confirmation modal
   *
   * Used by start and mark project as completed actions
   *
   * @param {Object} options
   * @param {Object} options.body
   * @param {String} [options.cancelLabel]
   * @param {String} options.confirmLabel
   * @param {String} [options.confirmStyle]
   * @param {Object} options.heading
   * @param {Function} [options.onSecondaryAction]
   * @param {Function} [options.onSecondaryActionSuccess]
   * @param {Object} options.payload
   * @param {Boolean} [options.secondaryActionLabel]
   */
  handleOpenModal({
    heading, body, confirmLabel, payload, confirmStyle = BS_STYLE.PRIMARY, cancelLabel = 'Cancel',
    onSecondaryAction, onSecondaryActionSuccess, secondaryActionLabel,
  } = {}) {
    this.setState({
      modal: {
        body,
        confirmLabel,
        confirmStyle,
        cancelLabel,
        heading,
        onSecondaryAction,
        onSecondaryActionSuccess,
        open: true,
        payload,
        secondaryActionLabel,
      },
    });
  }

  /**
   * Handles project's state change. Acts as a callback after the confirmation modal is submitted
   */
  handleProjectStateChange() {
    const {
      componentName, dispatch, deallocateAllFunds, tasksChangeStatus,
      match: { params: { orgAlias, id: projectId } },
    } = this.props;
    const { modal: { payload } } = this.state;
    const values = {};
    if (payload.action === PROJECT_STATE_CHANGE.MARK_AS_COMPLETED) {
      values.deallocateAllFunds = deallocateAllFunds;
      values.tasksChangeStatus = tasksChangeStatus;
    }
    return dispatch(
      updateProjectStateDS(orgAlias, projectId, payload.action, componentName, values),
    ).then(() => {
      this.handleCloseModal();
      this.handleRestoreState();
    }).catch((err) => {
      const error = err.errors ? err.errors._error : err._error;
      toastr.error('Oops!', (error || 'Could not update the project'));
      this.handleCloseModal();
    });
  }

  /**
   * Clones the task
   */
  async handleCloneTask() {
    const {
      history,
      dispatch,
      match: { params: { orgAlias, id: projectId } },
      task: { id: taskId },
      cloneTaskFormValues,
    } = this.props;

    const { task: { projectId: targetProjectId, id: targetTaskId } } = await dispatch(
      cloneTaskDS({
        orgAlias, projectId, taskId, values: cloneTaskFormValues,
      }),
    );

    history.push(
      projectViewTaskUrl(orgAlias, targetProjectId, targetTaskId, TASK_TABS.DASHBOARD),
    );
  }

  async approveTaskFn() {
    const {
      componentName,
      dispatch,
      history,
      match: { params: { id: projectId, orgAlias } },
      task: { id: taskId },
    } = this.props;

    const { task: { projectId: targetProjectId, id: targetTaskId } } = await dispatch(
      approveTaskDS({ componentName, orgAlias, projectId, taskId }),
    );

    history.push(
      projectViewTaskUrl(orgAlias, targetProjectId, targetTaskId, TASK_TABS.DASHBOARD),
    );
  }

  approveTaskModalOpenFn() {
    this.handleOpenModal({
      body: (
        <>
          <p>Are you sure you want to approve and complete this task?</p>
          <p>
            This can be reversed by adding a new provider to the task, or by re-opening one of the
            task&apos;s completed assignments.
          </p>
        </>
      ),
      cancelLabel: 'Cancel',
      confirmLabel: 'Approve and complete',
      heading: 'Approve and complete task',
      payload: { action: ACTION.APPROVE_TASK },
    });
  }

  /**
   * Handles a task being archived after the corresponding confirmation dialog is submitted
   */
  async handleArchiveTask() {
    const { history, task, match: { params: { orgAlias, id: projectId } } } = this.props;
    await projectArchiveTaskDS(orgAlias, projectId, task.id);
    history.push(projectViewUrl(orgAlias, projectId, PROJECT_TABS.TASKS));
  }

  async unarchiveTaskFn() {
    const { onTaskUpdated, task, match: { params: { orgAlias, id: projectId } } } = this.props;
    await projectUnarchiveTaskDS(orgAlias, projectId, task.id);
    onTaskUpdated();
  }

  async handleArchiveProject() {
    const { history, match: { params: { orgAlias, id: projectId } } } = this.props;
    await projectArchiveDS({ orgAlias, projectId });
    history.push(projectListUrl(orgAlias));
  }

  async handleUnarchiveProject() {
    const { dispatch, match: { params: { orgAlias, id: projectId } }, componentName } = this.props;
    await dispatch(projectUnarchiveDS({ orgAlias, projectId, componentName }));
  }

  handleTaskPendingActions() {
    const { history, match: { params: { orgAlias, id: projectId } }, task } = this.props;

    this.handleCloseModal();
    history.push(projectViewTaskUrl(orgAlias, projectId, task.id, TASK_TABS.DISCUSSION));
  }

  /**
   * Handles opening a message board form
   */
  handleOpenDiscussionBoardForm(taskAction, participantUserId = null) {
    const { task, history, match: { params: { orgAlias, id: projectId } } } = this.props;

    if (!task) {
      throw new Error('Task is not defined, yet the user tried to access the discussion board');
    }

    const action = CTA_ACTION_TO_DISCUSSION_BOARD_ACTION[taskAction];

    if (!action) {
      throw new Error(`Unknown CTA action ${taskAction}`);
    }

    history.push(
      projectViewTaskUrl(
        orgAlias, projectId, task.id, TASK_TABS.DISCUSSION, action, participantUserId,
      ),
    );
  }

  /**
   * Close the invitation modal
   */
  handleCloseModal() {
    const { modal: modalState } = this.state;

    this.setState({
      modal: {
        ...modalState,
        open: false,
      },
    });
  }

  /**
   * Restores the modal to its default state
   */
  handleRestoreState() {
    this.setState({
      modal: this.defaultModalState,
    });
  }

  /**
   * Handle the confirmation request of the invitation modal
   */
  async handleConfirmModal() {
    try {
      const { history } = this.props;
      const { modal: { payload } } = this.state;

      // Check if there is a redirect required
      if (payload.redirectUrl) {
        history.push(payload.redirectUrl);
        return;
      }

      // Just update the state of the project based on the designated required action
      if (payload.action === ACTION.ARCHIVE_TASK) {
        this.handleArchiveTask();
      } else if (payload.action === ACTION.APPROVE_TASK) {
        this.approveTaskFn();
      } else if (payload.action === ACTION.CLONE_PROJECT) {
        const form = DOCUMENT_GET_ELEMENT_BY_ID(CLONE_PROJECT_FORM_ID);
        if (form) {
          DISPATCH_EVENT_ON_ELEMENT(form, 'submit', { cancelable: true });
        }
      } else if (payload.action === ACTION.CLONE_TASK) {
        await this.handleCloneTask();
      } else if (payload.action === ACTION.ARCHIVE_PROJECT) {
        this.handleArchiveProject();
      } else if (TASK_DISCUSSION_BOARD_ACTIONS.includes(payload.action)) {
        this.handleOpenDiscussionBoardForm(payload.action, payload.participantUserId || null);
      } else if (Object.values(PROJECT_STATE_CHANGE).includes(payload.action)) {
        this.handleProjectStateChange();
      }
    } catch (err) {
      let error = (err.errors ? err.errors._error : err._error) || err.message;
      const fieldErrors = Object.entries(err.errors ? omit(err.errors, '_error', '_meta') : {});
      if (fieldErrors.length > 0) {
        error += ` (${fieldErrors.map(([key, fieldError]) => `${key}: ${fieldError}`).join(', ')})`;
      }
      toastr.error('Oops!', error);
      throw err;
    }
  }

  addProvidersFn() {
    const { history, location, dispatch, match: { params: { orgAlias, id } } } = this.props;

    const teamUrl = projectViewUrl(orgAlias, id, PROJECT_TABS.TEAM);
    if (location.pathname !== teamUrl) {
      history.push(teamUrl);
    }

    dispatch(modalOpenAC(ADD_MEMBERS_TO_PROJECT_MODAL_ID, {
      orgAlias,
      projectId: id,
      userType: PEOPLE_TYPE.PROVIDERS,
    }));
  }

  addManagersFn() {
    const { history, location, dispatch, match: { params: { orgAlias, id } } } = this.props;

    const teamUrl = projectViewUrl(orgAlias, id, PROJECT_TABS.TEAM);
    if (location.pathname !== teamUrl) {
      history.push(teamUrl);
    }

    dispatch(modalOpenAC(ADD_MEMBERS_TO_PROJECT_MODAL_ID, {
      orgAlias,
      projectId: id,
      userType: PEOPLE_TYPE.MANAGERS,
    }));
  }

  addTaskManagersFn() {
    const { history, location, task, dispatch, match: { params: { orgAlias, id } } } = this.props;

    const tasmManagersTabUrl = projectViewTaskUrl(orgAlias, id, task.id, TASK_TABS.MANAGERS);
    if (location.pathname !== tasmManagersTabUrl) {
      history.push(tasmManagersTabUrl);
    }

    dispatch(modalOpenAC(ADD_MANAGERS_TO_TASK_MODAL_ID, {
      orgAlias,
      userType: PEOPLE_TYPE.MANAGERS,
      projectId: task.projectId,
      taskId: task.id,
    }));
  }

  requestBudgetFn() {
    const { history, match: { params: { orgAlias, id } }, pendingPurchaseOrders } = this.props;

    if (pendingPurchaseOrders) {
      const body = (
        <p>
          There are
          {' '}
          <strong>{pendingPurchaseOrders}</strong>
          {' '}
          Budget requests pending that need to be handled before requesting more budget.
        </p>
      );

      return this.handleOpenModal({
        heading: 'There are budget requests pending',
        body,
        confirmLabel: 'Close',
        cancelLabel: null,
        payload: {},
      });
    }

    return history.push(projectRequestBudgetUrl(orgAlias, id));
  }

  returnFundsFn() {
    const { dispatch, match: { params: { orgAlias, id } } } = this.props;
    dispatch(modalOpenAC(RETURN_FUNDS_MODAL_ID, { orgAlias, projectId: id }));
  }

  raiseWorksheetFn() {
    const { history, match: { params: { orgAlias, id } } } = this.props;
    history.push(projectSubmitWorksheetUrl(orgAlias, id));
  }

  claimExpenseFn() {
    const { history, match: { params: { orgAlias, id } } } = this.props;
    history.push(projectClaimExpenseUrl(orgAlias, id));
  }

  editProjectFn() {
    const { history, match: { params: { orgAlias, id } } } = this.props;
    history.push(projectEditUrl(orgAlias, id));
  }

  transferProjectOwnershipFn() {
    const { dispatch } = this.props;
    dispatch(modalOpenAC(PROJECT_OWNERSHIP_MODAL_ID));
  }

  transferTaskOwnershipFn() {
    const { dispatch } = this.props;
    dispatch(modalOpenAC(TASK_OWNERSHIP_MODAL_ID));
  }

  startProjectFn() {
    const {
      match: { params: { orgAlias, id } },
      pendingPurchaseOrders,
      project: { budget, providers_assigned: hasProvidersAssigned },
    } = this.props;

    const payload = { orgAlias, projId: id, action: PROJECT_STATE_CHANGE.START };
    const body = [];

    if (hasProvidersAssigned) {
      body.push((
        <p>
          The project team members will get notified to start working on the project.
        </p>
      ));
    } else {
      body.push((
        <p>You can also invite team members after the project has started.</p>
      ));
    }

    if (!budget && !pendingPurchaseOrders) {
      body.push((
        <p>
          Remember to request budget for the project so that your team members
          can submit worksheets and claim expenses.
        </p>
      ));
    }

    this.handleOpenModal({
      heading: 'Start the project',
      body,
      cancelLabel: 'Cancel',
      confirmLabel: 'Start',
      payload,
    });
  }

  markProjectAsCompletedFn() {
    const { dispatch } = this.props;
    dispatch(modalOpenAC(COMPLETE_MODAL_ID));
  }

  reopenProjectFn() {
    const { dispatch } = this.props;
    dispatch(modalOpenAC(REOPEN_MODAL_ID));
  }

  createTaskFn() {
    const { history, match: { params: { orgAlias, id } } } = this.props;
    history.push(projectCreateTaskUrl(orgAlias, id));
  }

  editTaskFn() {
    const { history, match: { params: { orgAlias, id } }, task } = this.props;
    history.push(projectEditTaskUrl(orgAlias, id, task.id));
  }

  archiveTaskFn() {
    const { task, taskAllowedActions } = this.props;
    let body;
    let payload;
    let confirmStyle;
    let confirmLabel;
    let cancelLabel;

    if (taskAllowedActions.canArchiveTask) {
      payload = { action: ACTION.ARCHIVE_TASK };
      confirmStyle = BS_STYLE.DANGER;
      confirmLabel = 'Archive task';
      cancelLabel = 'Cancel';
      body = (
        <React.Fragment>
          <p>
            You are about to archive the task “
            {task.title}
            ”. If you proceed, you will not be able to view the task in the project’s task list
            by default, nor you’ll be able to update it.
          </p>
          <p>
            Are you sure you want to do that?
          </p>
        </React.Fragment>
      );
    } else {
      body = 'You can’t archive the task when its status is pending or in progress.';
      confirmLabel = 'Close';
      cancelLabel = false;
      confirmStyle = BS_STYLE.PRIMARY;
      payload = {};
    }

    this.handleOpenModal({
      heading: 'Archive the task?',
      body,
      confirmLabel,
      confirmStyle,
      cancelLabel,
      payload,
    });
  }

  handleTaskCompletionFn() {
    const { dispatch, task, taskAllowedActions } = this.props;

    const pendingAssignments = task.assignments.filter(a => (
      a.status === TASK_ASSIGNMENT_STATUS.PENDING
    ));

    if (pendingAssignments.length) {

      return this.handleOpenModal({
        heading: 'There are task assignments pending',
        body: (
          <div className="task-modal-completion">
            <PendingTaskAssignments
              onFollowLink={this.handleCloseModal}
              pendingAssignments={pendingAssignments}
            />
          </div>
        ),
        confirmLabel: 'Close',
        cancelLabel: null,
        payload: {},
      });

    }

    const atLeastOneWorksheetMissing = this.getAssignmentsWithoutWorksheets().length > 0;
    const canRaiseWorksheets = (
      atLeastOneWorksheetMissing
      && taskAllowedActions.canRaiseWorksheetsForProvidersWhenCompleting
    );

    const modalOptions = {
      heading: 'Complete task',
      confirmLabel: 'Complete task',
      confirmStyle: BS_STYLE.SUCCESS,
      payload: { action: ACTION.COMPLETE_TASK },
    };

    let appendToBody = null;

    if (canRaiseWorksheets) {
      modalOptions.secondaryActionLabel = 'Submit assignee worksheets';
      modalOptions.onSecondaryActionSuccess = () => {
        dispatch(modalOpenAC(TASK_RAISE_WORKSHEETS_MODAL_ID));
      };
      appendToBody = (
        <p>
          You can optionally first submit worksheets on behalf of the task assignees and then mark
          the task as completed, or simply mark the task as completed and the assignees will submit
          their worksheets by themselves.
        </p>
      );
    }

    const { hasPending, body: pendingActionsBody } = this.getPendingTaskObjections(appendToBody);

    const body = hasPending ? pendingActionsBody : (
      <div className="task-modal-completion" data-testid="task-modal-completion">
        <p>
          You are about to mark this task as completed, so please make sure that you have
          everything at hand.
        </p>
        <p>
          All of the assignments are going to be marked as completed and you should
          be able to review each assignee separately within their private chat.
        </p>
        <p>
          Are you sure you want to complete the task?
        </p>
        {appendToBody}
      </div>
    );

    return this.handleOpenModal({ ...modalOptions, body });
  }

  handleProposeTaskRate() {
    const { participantUserId } = this.props;
    return this.handleOpenDiscussionBoardForm(
      ACTION.PROPOSE_TASK_RATE, participantUserId,
    );
  }

  handleTaskStopFn() {
    return this.handleOpenDiscussionBoardForm(ACTION.STOP_TASK);
  }

  handleTaskAddProvidersFn() {
    return this.handleOpenDiscussionBoardForm(ACTION.ASSIGN_TASK);
  }

  handleTaskAssignmentCompletionFn() {
    const { participantUserId } = this.props;
    return this.handleOpenDiscussionBoardForm(
      ACTION.COMPLETE_TASK_ASSIGNMENT, participantUserId,
    );
  }

  handleProjectCloneFn() {
    const {
      dispatch,
      history,
      match: { params: { orgAlias } },
      project: { attachments, clients, id: projectId, skills, tags, title },
    } = this.props;

    const attachmentsCount = Array.isArray(attachments) ? attachments.length : 0;
    const clientsCount = Array.isArray(clients) ? clients.length : 0;
    const skillsCount = skills.length;
    const tagsCount = Array.isArray(tags) ? tags.length : 0;


    return this.handleOpenModal({
      body: (
        <ProjectCloneForm
          attachmentsCount={attachmentsCount}
          clientsCount={clientsCount}
          initialValues={{
            includeAttachments: [{ value: attachmentsCount > 0 }],
            includeClients: [{ value: clientsCount > 0 }],
            includeSkills: [{ value: skillsCount > 0 }],
            includeTags: [{ value: tagsCount > 0 }],
            title: `Copy of ${title}`,
          }}
          onSubmit={async values => {
            const finalValues = Object.keys(values).reduce(
              (acc, field) => {
                let value = values[field];
                if (field !== 'title') {
                  value = Array.isArray(value) && value.length > 0 ? value[0].value : false;
                }
                acc[field] = value;
                return acc;
              },
              {},
            );
            try {
              const { id: newProjectId } = await dispatch(cloneProjectDS({
                orgAlias, projectId, values: finalValues,
              }));
              history.push(projectViewUrl(orgAlias, newProjectId));
              this.handleCloseModal();
            } catch (err) {
              const error = err.errors ? err.errors._error : err._error;
              toastr.error('Oops!', error || 'Could not clone the project');
            }
          }}
          skillsCount={skillsCount}
          tagsCount={tagsCount}
        />
      ),
      confirmLabel: 'Clone',
      confirmStyle: BS_STYLE.PRIMARY,
      heading: 'Clone the project',
      payload: { action: ACTION.CLONE_PROJECT },
    });
  }

  handleTaskCloneFn() {
    const {
      organization: { task_deadline_mandatory: taskDeadlineMandatory },
      project: { id: projectId },
      task: { title: taskTitle },
    } = this.props;

    const initialValues = {
      attachments: true,
      deadline: null,
      items: true,
      project_id: projectId,
      skills: true,
      starts_on: null,
      tags: true,
      title: `Copy of ${taskTitle}`,
    };

    const body = (
      <React.Fragment>
        <p className="mb-4">
          Clone this task to create an exact replica.
          Please select which of the following data you want to copy over to the new task:
        </p>
        <TaskCloneComponentsSelection
          className="mb-4"
          initialValues={initialValues}
          taskDeadlineMandatory={taskDeadlineMandatory}
        />
        <p>
          Once you clone this task you will get redirected to the new copy you just created.
        </p>
        <p>
          Are you sure you want to clone this task?
        </p>
      </React.Fragment>
    );

    return this.handleOpenModal({
      heading: 'Clone the task',
      body,
      confirmLabel: 'Clone',
      confirmStyle: BS_STYLE.PRIMARY,
      payload: { action: ACTION.CLONE_TASK },
    });
  }

  handleTaskCompletionRequestFn() {
    const { task } = this.props;
    return this.handleOpenDiscussionBoardForm(ACTION.REQUEST_COMPLETION, task.ownerId);
  }

  archiveProjectFn() {
    const {
      project: { currency_symbol: projectCurrency, budgetBreakdown },
      pendingTasks,
    } = this.props;
    let hasPending = false;
    let body;
    let heading;
    let payload;
    let confirmLabel;
    let confirmStyle = BS_STYLE.PRIMARY;

    ({
      hasPending, body, heading, payload, confirmLabel,
    } = this.getPendingDocumentObjections('archived'));

    if (!hasPending) {
      heading = 'Archive the project';
      payload = { action: ACTION.ARCHIVE_PROJECT };
      confirmLabel = 'Archive project';
      confirmStyle = BS_STYLE.DANGER;
      body = (
        <ProjectFinalizeForm
          text="The providers that participate in the project will get notified."
          budgetBreakdown={budgetBreakdown}
          projectCurrency={projectCurrency}
          showFundsDeallocationOption={false}
          fundsDeallocationMessage="The funds will be returned to the organization"
          initialValues={{
            deallocate_all_funds: PROJECT_COMPLETION_FUNDS.RETURN_TO_ORG_OWNER,
          }}
          pendingTasks={pendingTasks}
        />
      );
    }

    return this.handleOpenModal({
      heading,
      body,
      confirmLabel,
      confirmStyle,
      payload,
    });
  }

  leaveTaskFn() {
    const { task: { ownerId: taskOwnerId }, taskActions } = this.props;
    const hasBlockingActions = taskActions.some(act => BLOCKING_TASK_ACTIONS.includes(act.name));
    let body;

    if (hasBlockingActions) {
      body = (
        <p>
          You can’t leave the task because there are actions in the
          {' '}
          <a
            onClick={this.handleTaskPendingActions}
            className="text-primary"
          >
            discussion board
          </a>
          {' '}
          that require your attention
        </p>
      );

      return this.handleOpenModal({
        heading: 'There are actions pending in the task’s discussion board',
        body,
        confirmLabel: 'Close',
        cancelLabel: null,
        payload: {},
      });
    }

    return this.handleOpenDiscussionBoardForm(ACTION.LEAVE_TASK, taskOwnerId);
  }

  handleRaiseWorksheetForTaskFn() {
    const {
      history,
      match: { params: { orgAlias, id: projectId } },
      task,
    } = this.props;

    if (task.worksheetAccessControl.canCreateWorksheetFor) {
      history.push(projectSubmitWorksheetUrl(orgAlias, projectId, task.id));
      return;
    }

    this.handleOpenModal({
      heading: 'You cannot submit a worksheet for this task',
      body: (
        <p>
          {task.worksheetAccessControl.reasonLabel}
        </p>
      ),
      confirmLabel: 'Close',
      cancelLabel: null,
      payload: {},
    });
  }

  handleRaiseWorksheetsForTaskFn() {
    const { dispatch } = this.props;
    dispatch(modalOpenAC(TASK_RAISE_WORKSHEETS_MODAL_ID));
  }

  handleViewWorksheetsFn() {
    const { history, match: { params: { orgAlias } }, task } = this.props;

    history.push(financeWorksheetsUrl(orgAlias, { taskId: task.id }));
  }

  importWorksheets() {
    const { history, match: { params: { orgAlias, id: projectId } } } = this.props;
    const url = projectWorksheetImportUrl(
      orgAlias, projectId, PROJECT_WORKSHEET_IMPORT_STEP.DOWNLOAD,
    );
    history.push(url);
  }

  render() {
    const {
      primary: primaryActions, secondary: secondaryActions,
    } = this.getAvailableActions();

    let primaryAction;
    let primaryDropdownItems = [];
    let secondaryDropdownItems = [];

    if (primaryActions.length > 0) {
      [primaryAction, ...primaryDropdownItems] = primaryActions;
      secondaryDropdownItems = secondaryActions;
    } else {
      [primaryAction, ...primaryDropdownItems] = secondaryActions;
    }

    if (!primaryAction && !primaryDropdownItems) {
      return null;
    }

    const {
      componentName, pendingTasks, project, rootComponentName, task, taskAllowedActions,
    } = this.props;
    const { modal } = this.state;

    const taskAssignmentsWithWorksheetMissing = this.getAssignmentsWithoutWorksheets();
    const canRaiseWorksheets = (
      (
        taskAllowedActions.canRaiseWorksheetsForProvidersWhenCompleting
        || taskAllowedActions.canRaiseWorksheetsForProvidersAfterCompleting
      )
      && taskAssignmentsWithWorksheetMissing.length > 0
    );

    return (
      <React.Fragment>
        <ModalConfirm
          data-testid="project-cta-modal-confirm"
          onClose={this.handleCloseModal}
          onClosed={this.handleRestoreState}
          onConfirm={this.handleConfirmModal}
          {...modal}
        />
        <ProjectCompleteModal
          PendingDocumentsLabelComponent={PendingDocumentsLabel}
          pendingTasksCount={pendingTasks}
          project={project}
          rootComponentName={componentName}
        />
        <ProjectReopenModal rootComponentName={componentName} />
        {canRaiseWorksheets && (
          <TaskRaiseWorksheetsPanel
            assignments={taskAssignmentsWithWorksheetMissing}
            rootComponentName={rootComponentName}
            taskIsCompleted={task && task.status === TASK_STATUS.COMPLETED}
          />
        )}

        {/** Desktop view */}
        {primaryAction && !!(primaryDropdownItems.length || secondaryDropdownItems.length) && (
          <Dropdown as={ButtonGroup} className="td-dropdown d-none d-md-inline-block">
            <TDButton
              data-testid="project-cta-start-project"
              variant={BS_STYLE.PRIMARY}
              label={BUTTON_LABELS[primaryAction]}
              disabledMsg={DISABLED_MESSAGES[primaryAction] || ''}
              onClick={this.actionCallbacks[primaryAction]}
            />

            <Dropdown.Toggle split variant={BS_STYLE.PRIMARY}>
              <span data-testid="project-cta-dropdown-toggle" className={ICON.CHEVRON_DOWN} />
            </Dropdown.Toggle>

            <Dropdown.Menu alignRight>
              {primaryDropdownItems && primaryDropdownItems.map(action => (
                <Dropdown.Item
                  data-testid="project-cta-primary-dropdown-item"
                  key={`action-${action}`}
                  onClick={this.actionCallbacks[action]}
                  className={CTA_ACTION_TO_CSS_CLASS[action] || ''}
                >
                  {BUTTON_LABELS[action]}
                </Dropdown.Item>
              ))}
              {secondaryDropdownItems && secondaryDropdownItems.length > 0 && (
                <React.Fragment>
                  {primaryDropdownItems.length > 0 && (
                    <Dropdown.Divider />
                  )}
                  {secondaryDropdownItems.map(action => (
                    <Dropdown.Item
                      data-testid="project-cta-secondary-dropdown-item"
                      key={`action-${action}`}
                      onClick={this.actionCallbacks[action]}
                      className={CTA_ACTION_TO_CSS_CLASS[action] || ''}
                    >
                      {BUTTON_LABELS[action]}
                    </Dropdown.Item>
                  ))}
                </React.Fragment>
              )}
            </Dropdown.Menu>
          </Dropdown>
        )}

        {!!primaryAction && !primaryDropdownItems.length && !secondaryDropdownItems.length && (
          <TDButton
            className="d-none d-md-inline-block"
            data-testid="project-cta-primary-button-no-secondary"
            variant={BS_STYLE.PRIMARY}
            label={BUTTON_LABELS[primaryAction]}
            disabledMsg={DISABLED_MESSAGES[primaryAction] || ''}
            onClick={this.actionCallbacks[primaryAction]}
          />
        )}

        {/** Mobile view */}
        {(
          primaryAction
          || (primaryDropdownItems && primaryDropdownItems.length > 0)
          || (secondaryDropdownItems && secondaryDropdownItems.length > 0)
        ) && (
          <TDDropButton overlayClassName="mobile-breadcrumbs-cta-dropdown" className="d-inline d-md-none">
            {primaryAction && (
              <Dropdown.Item
                disabled={(DISABLED_MESSAGES[primaryAction])}
                key="action-mobile-primary-action"
                onClick={this.actionCallbacks[primaryAction]}
              >
                {BUTTON_LABELS[primaryAction]}
              </Dropdown.Item>
            )}
            {primaryDropdownItems && primaryDropdownItems.map(action => (
              <Dropdown.Item
                key={`action-mobile-${action}`}
                onClick={this.actionCallbacks[action]}
                className={CTA_ACTION_TO_CSS_CLASS[action] || ''}
              >
                {BUTTON_LABELS[action]}
              </Dropdown.Item>
            ))}

            {secondaryDropdownItems && secondaryDropdownItems.length > 0 && (
              <React.Fragment>
                {primaryDropdownItems.length > 0 && (
                  <Dropdown.Divider />
                )}
                {secondaryDropdownItems.map(action => (
                  <Dropdown.Item
                    key={`action-mobile-${action}`}
                    onClick={this.actionCallbacks[action]}
                    className={CTA_ACTION_TO_CSS_CLASS[action] || ''}
                  >
                    {BUTTON_LABELS[action]}
                  </Dropdown.Item>
                ))}
              </React.Fragment>
            )}
          </TDDropButton>
        )}
      </React.Fragment>
    );
  }
}

ProjectCta.propTypes = {
  activeUserCard: userCardSpec.isRequired,
  cloneTaskFormValues: PropTypes.object,
  componentName: PropTypes.string.isRequired,
  currentTab: PropTypes.oneOf(Object.values(PROJECT_TABS)).isRequired,
  deallocateAllFunds: PropTypes.oneOf(Object.values(PROJECT_COMPLETION_FUNDS)),
  dispatch: PropTypes.func.isRequired,
  expenseAllowedActions: expenseAllowedActionsSpec,
  history: routerHistorySpec.isRequired,
  location: PropTypes.object.isRequired,
  match: routerMatchSpec.isRequired,
  onTaskUpdated: PropTypes.func,
  organization: orgSpec.isRequired,
  participantUserId: PropTypes.number,
  pendingApplications: PropTypes.number,
  pendingExpenses: PropTypes.number,
  pendingPurchaseOrders: PropTypes.number,
  pendingTasks: PropTypes.number,
  pendingWorksheets: PropTypes.number,
  project: projectSpec.isRequired,
  projectAllowedActions: projectAllowedActionsSpec,
  rootComponentName: PropTypes.string,
  task: projectTaskSpec,
  taskAllowedActions: taskAllowedActionsSpec,
  taskActions: PropTypes.arrayOf(PropTypes.object),
  taskAssignments: PropTypes.arrayOf(taskAssignmentSpec),
  taskCounts: taskInfoSpec,
  tasksChangeStatus: PropTypes.oneOf(Object.values(PROJECT_TASKS_STATUS_CHANGE)),
  worksheetAllowedActions: worksheetAllowedActionsSpec,
};

ProjectCta.defaultProps = {
  cloneTaskFormValues: { items: true, attachments: true },
  deallocateAllFunds: PROJECT_COMPLETION_FUNDS.RETURN_TO_ORG_OWNER,
  expenseAllowedActions: {},
  onTaskUpdated: () => {},
  participantUserId: 0,
  pendingApplications: 0,
  pendingExpenses: 0,
  pendingPurchaseOrders: 0,
  pendingTasks: 0,
  pendingWorksheets: 0,
  projectAllowedActions: {},
  rootComponentName: '',
  task: {},
  taskActions: [],
  taskAllowedActions: {},
  taskAssignments: [],
  taskCounts: {},
  tasksChangeStatus: null,
  worksheetAllowedActions: {},
};

const mapStateToProps = (state) => {
  const markAsCompletedFormValues = getFormValues(FINALIZE_FORM_ID)(state) || {};
  const cloneTaskFormValues = getFormValues(CLONE_TASK_FORM_ID)(state) || {};

  return {
    activeUserCard: selectActiveUserCard(state),
    cloneTaskFormValues,
    deallocateAllFunds: markAsCompletedFormValues.deallocate_all_funds,
    organization: selectActiveOrg(state),
    tasksChangeStatus: markAsCompletedFormValues.task_status_change,
  };
};

const mapDispatchToProps = dispatch => ({
  dispatch,
});

const ProjectCtaConnected = connect(
  mapStateToProps,
  mapDispatchToProps,
)(ProjectCta);

export default withRouter(ProjectCtaConnected);
