import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { toastr } from 'react-redux-toastr';
import { Link, withRouter } from 'react-router-dom';

import TDButton from 'core/assets/js/components/TDButton.jsx';
import { WRITE_TO_CLIPBOARD } from 'core/assets/js/config/settings';
import { orgSpec } from 'organizations/assets/js/lib/objectSpecs';
import { modalOpenAC } from 'core/assets/js/ducks/modalLauncher';
import { formatDate } from 'core/assets/js/lib/utils';
import { routerMatchSpec } from 'core/assets/js/lib/objectSpecs';
import { orgUserProfileUrl } from 'people/urls';
import {
  BS_SIZE, BS_TOOLTIP_PLACEMENT, ICON, USER_TYPE, IMG_SIZE, POPPER_PLACEMENT,
} from 'core/assets/js/constants';
// import { getEffectiveTaskInfo } from 'projects/assets/js/lib/helpers';
import { taskAssignmentSpec, projectTaskSpec, taskAllowedActionsSpec } from 'projects/assets/js/lib/objectSpecs';
import { MODAL_ID as TASK_OWNERSHIP_MODAL_ID } from 'projects/assets/js/components/TaskOwnerModal.jsx';
import ElementWithPopOver from 'core/assets/js/components/ElementWithPopOver.jsx';
// import TaskProgressBar from 'projects/assets/js/components/TaskProgressBar.jsx';
import TDElementWithTooltip from 'core/assets/js/components/TDElementWithTooltip.jsx';
import ClaimTaskOwnership from 'projects/assets/js/components/ClaimTaskOwnership.jsx';
import { selectActiveOrg } from 'organizations/assets/js/reducers/organizations';
import ProfilePic from 'core/assets/js/components/ProfilePic.jsx';

const TaskDetailsWidget = ({
  activeOrg: {
    task_managers_enabled: taskManagersEnabled,
  },
  allowedActions,
  assignments,
  dispatch,
  match: { params: { orgAlias } },
  task,
}) => {
  const { createdAt, deadline, startsOn, owner, previousOwner, managers } = task;

  let ownerLink;
  if (owner && owner.profile) {
    ownerLink = (
      <Link to={orgUserProfileUrl(orgAlias, USER_TYPE.MANAGER, owner.id)}>
        {owner.profile.name}
      </Link>
    );
  }

  const handleTransferOwnership = () => (
    dispatch(modalOpenAC(TASK_OWNERSHIP_MODAL_ID))
  );

  const {
    canTransferOwnership, canClaimOwnership, canViewAllAssignments,
    canViewAcceptedAssignments,
  } = allowedActions;

  // const { label: statusLabel, progress, statusClass } = getEffectiveTaskInfo(activeOrg, task);

  if (canTransferOwnership) {
    ownerLink = (
      <span onClick={handleTransferOwnership}>
        <TDElementWithTooltip
          el={(
            <span className="imitate-link">
              {owner.profile.name}
            </span>
          )}
          placement={BS_TOOLTIP_PLACEMENT.TOP}
          tooltipMsg="Transfer task ownership"
        />
      </span>
    );
  } else if (canClaimOwnership) {
    const claimOwnershipButtonLabel = (
      <TDElementWithTooltip
        el={(
          <span className="imitate-link">
            {owner.profile.name}
          </span>
        )}
        placement={BS_TOOLTIP_PLACEMENT.TOP}
        tooltipMsg="Claim task ownership"
      />
    );

    ownerLink = (
      <ClaimTaskOwnership
        buttonLabel={claimOwnershipButtonLabel}
        task={task}
      />
    );
  }
  return (
    <div className="task-summary">
      <div className="task-meta">
        {/* <div className="d-block">
          <TaskProgressBar
            id={task.id}
            progress={progress}
            statusClass={statusClass}
            className="task-progress-bar--thick mb-2"
          />

          <div className="d-flex align-items-center">
            Progress
            <span className="ml-auto">
              {progress !== null ? `${progress}%` : statusLabel}
            </span>
          </div>
        </div> */}

        <div className="d-flex align-items-center">
          Reference
          <TDButton
            bsSize={BS_SIZE.SMALL}
            className="ml-auto"
            label={task.id}
            onClick={async () => {
              try {
                await WRITE_TO_CLIPBOARD(task.id);
                toastr.success(
                  'Well Done!', `${task.id} has been copied to your clipboard`,
                );
              } catch (error) {
                toastr.error(
                  'Oh Snap!', `Could not copy to clipboard: ${error.message}`,
                );
              }
            }}
          />
        </div>

        <div className="d-flex align-items-center">
          Created at
          <span className="ml-auto">
            {createdAt ? formatDate(createdAt) : '-'}
          </span>
        </div>

        {startsOn && (
          <div className="d-flex align-items-center">
            Starts on
            <span className="ml-auto">
              {startsOn ? formatDate(startsOn) : '-'}
            </span>
          </div>
        )}

        <div className="d-flex align-items-center">
          Deadline
          <span className="ml-auto">
            {deadline ? formatDate(deadline) : '-'}
          </span>
        </div>

        {ownerLink && (
          <div className="d-flex align-items-center">
            Owner
            <span className="ml-auto">
              {ownerLink}
              {(canClaimOwnership || canTransferOwnership) && <i className={`${ICON.EDIT} ml-1 align-middle`} />}
              {previousOwner?.name && (
                <ElementWithPopOver
                  className="d-inline-block"
                  placement="left"
                  popOverClassName="project-task__details-popover"
                  element={<i className={`${ICON.CIRCLE_INFO} ml-2 align-middle`} />}
                  popOverContent={(
                    <span>
                      Previous owner of this task is
                      {' '}
                      {' '}
                      <Link to={orgUserProfileUrl(orgAlias, USER_TYPE.MANAGER, previousOwner.id)}>
                        {previousOwner.name}
                      </Link>
                      .
                      <br />
                      Current task owner is
                      {' '}
                      {' '}
                      <Link to={orgUserProfileUrl(orgAlias, USER_TYPE.MANAGER, owner.id)}>
                        {owner.profile.name}
                      </Link>
                    </span>
                  )}
                  popOverTitle="Ownership Information"
                />
              )}
            </span>
          </div>
        )}

        {taskManagersEnabled && Array.isArray(managers) && managers.length > 0 && (
          <div className="d-flex align-items-center">
            Task Managers
            <span className="ml-auto">
              {managers.length <= 1 ? (
                <Link to={orgUserProfileUrl(orgAlias, USER_TYPE.MANAGER, managers[0].id)}>
                  {managers[0].profile.name}
                </Link>
              ) : (
                <>
                  <Link to={orgUserProfileUrl(orgAlias, USER_TYPE.MANAGER, managers[0].id)}>
                    {managers[0].profile.name}
                  </Link>
                  {' '}
                  and
                  {' '}
                  <ElementWithPopOver
                    className="d-inline-block"
                    placement={POPPER_PLACEMENT.BOTTOM_END}
                    popOverClassName="project-task__details-manager-list-popover"
                    element={(
                      <span className="btn-link">
                        {managers.length - 1}
                        {' '}
                        more
                      </span>
                    )}
                    popOverContent={(
                      <>
                        {managers.slice(1).map((manager) => (
                          <div key={manager.id} className="py-2">
                            <ProfilePic
                              url={manager.profile.avatar}
                              alt={manager.profile.name}
                              size={[IMG_SIZE.XSMALL, IMG_SIZE.XSMALL]}
                              className="mr-3 align-self-center"
                            />
                            <Link to={orgUserProfileUrl(orgAlias, USER_TYPE.MANAGER, manager.id)}>
                              {manager.profile.name}
                            </Link>
                          </div>
                        ))}
                      </>
                    )}
                  />
                </>
              )}
            </span>
          </div>
        )}

        {(canViewAllAssignments || canViewAcceptedAssignments) && (
          <div className="d-flex align-items-center">
            Task Assignees
            <span className="ml-auto">
              {assignments.length}
            </span>
          </div>
        )}
      </div>
    </div>
  );
};

TaskDetailsWidget.propTypes = {
  activeOrg: orgSpec.isRequired,
  allowedActions: taskAllowedActionsSpec,
  assignments: PropTypes.arrayOf(taskAssignmentSpec),
  dispatch: PropTypes.func.isRequired,
  match: routerMatchSpec.isRequired,
  task: projectTaskSpec.isRequired,
};

TaskDetailsWidget.defaultProps = {
  allowedActions: {},
  assignments: [],
};

const mapStateToProps = (state) => ({
  activeOrg: selectActiveOrg(state),
});
const mapDispatchToProps = dispatch => ({ dispatch });

const TaskDetailsWidgetConnected = connect(
  mapStateToProps,
  mapDispatchToProps,
)(TaskDetailsWidget);

export default withRouter(TaskDetailsWidgetConnected);
