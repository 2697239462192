import PropTypes from 'prop-types';
import React from 'react';
import { Dropdown } from 'react-bootstrap';
import { connect } from 'react-redux';
import { toastr } from 'react-redux-toastr';
import { isEmpty } from 'lodash';
import { withRouter, Link } from 'react-router-dom';
import qs from 'query-string';

import StatusTag from 'core/assets/js/components/StatusTag.jsx';
import TDButton from 'core/assets/js/components/TDButton.jsx';
import TDDropdown from 'core/assets/js/components/TDDropdown.jsx';
import { BS_STYLE, BS_SIZE, ICON } from 'core/assets/js/constants';
import axios from 'core/assets/js/lib/tdAxios';
import { viewDocumentAssignment } from 'core/assets/js/lib/utils-jsx';
import { calculateExpirationDate } from 'documents/assets/js/lib/utils';
import DocumentAssignmentViewDropdownItem from 'documents/assets/js/components/DocumentAssignmentViewDropdownItem.jsx';
import {
  DOCUMENT_ASSIGNMENT_SIGNATURE_STATUS,
  DOCUMENT_ASSIGNMENT_STATUS,
  DOCUMENT_ASSIGNMENT_STATUS_CLASS,
  DOCUMENT_ASSIGNMENT_STATUS_LABEL,
} from 'documents/assets/js/constants';
import { cancelDocumentAssignmentApiUrl, documentAssignmentSignUrl } from 'documents/urls';
import { formatDate } from 'core/assets/js/lib/utils';
import { getHasOrgAccess } from 'accounts/assets/js/reducers/auth';
import { routerMatchSpec, routerHistorySpec } from 'core/assets/js/lib/objectSpecs';
import { projectInvitationsUrl, projectViewTaskUrl } from 'projects/urls';

const DocumentAssignmentsListItem = ({
  documentAssignment,
  hasOrgAccess,
  history,
  location,
  match,
  onUpdated,
}) => {
  if (isEmpty(documentAssignment)) {
    return null;
  }

  const { params: { orgAlias } } = match;
  const { allowedActions, document, invitation, signatures, signedAt, status } = documentAssignment;
  const { canCancel, canSign, canView } = allowedActions || {};
  const isManager = hasOrgAccess({ requireManager: true });

  const onSignDocumentClick = () => {
    history.push({
      pathname: documentAssignmentSignUrl(orgAlias, documentAssignment.id),
      search: qs.stringify({
        ...qs.parse(location.search),
        backUrl: projectInvitationsUrl(
          orgAlias, invitation?.id ? { expand: invitation?.id } : {},
        ),
        next: projectInvitationsUrl(orgAlias),
      }),
      state: { from: location.pathname },
    });
  };

  const { title, version } = document;

  let statusLabel = DOCUMENT_ASSIGNMENT_STATUS_LABEL[status];
  let pendingCountersignerEmails = [];
  if (
    status === DOCUMENT_ASSIGNMENT_STATUS.PENDING
    && Array.isArray(signatures)
    // The assignee has signed
    && signatures.some(s => (
      !s.isCounterSigner && s.status === DOCUMENT_ASSIGNMENT_SIGNATURE_STATUS.SIGNED
    ))
  ) {
    pendingCountersignerEmails = signatures.reduce(
      (acc, signature) => {
        if (
          signature.isCounterSigner
          && signature.status === DOCUMENT_ASSIGNMENT_SIGNATURE_STATUS.PENDING
        ) {
          acc.push(signature.email);
        }
        return acc;
      },
      [],
    );
  }
  const hasPendingCountersignerEmails = pendingCountersignerEmails.length > 0;
  if (pendingCountersignerEmails.length > 0) {
    statusLabel = 'Pending countersigning';
  }

  return (
    <div className="bg-light-grey px-4 py-3 mb-3 rounded">
      <div className="d-flex row">
        <div className="col-12 col-sm">
          <span
            className="text-secondary font-weight-bold cursor-pointer"
            onClick={e => {
              e.stopPropagation();
              viewDocumentAssignment(orgAlias, documentAssignment, history, location.pathname);
            }}
          >
            {title}
          </span>
          <br />
          {invitation?.task && (
            <>
              <span className="discreet font-smaller mr-2">Task:</span>
              <Link
                to={projectViewTaskUrl(orgAlias, invitation.task.projectId, invitation.task.id)}
              >
                {invitation.task.title}
              </Link>
              <br />
            </>
          )}
          <span className="discreet font-smaller">
            {`Confirmed date: ${signedAt ? formatDate(signedAt) : '-'}`}
          </span>
          <br />
          <span className="discreet font-smaller">
            {`Expiration Date: ${calculateExpirationDate(documentAssignment)}`}
          </span>
        </div>

        <div className="col-12 col-sm-auto mt-3 mt-md-0 align-self-center align-items-center ml-auto me-5 pe-5 d-flex flex-nowrap">
          <div className="mr-5 pr-5 d-flex text-nowrap align-items-center">
            {isManager && (
              <div className="mr-5 pr-5">
                <span className="discreet">v. </span>
                {version}
              </div>
            )}

            <StatusTag
              hideTitle
              label={statusLabel}
              reason={(
                hasPendingCountersignerEmails
                && `Awaiting countersigning by ${pendingCountersignerEmails.join(' and ')}`
              )}
              statusClass={DOCUMENT_ASSIGNMENT_STATUS_CLASS[status]}
            />
          </div>

          <div className="ml-auto">
            <TDDropdown
              variant={BS_STYLE.LINK}
              noCaret
              alignRight
              toggle={(
                <TDButton
                  bsSize={BS_SIZE.SMALL}
                  variant={BS_STYLE.DEFAULT}
                  btnIcon={<i className={ICON.CHEVRON_DOWN} />}
                />
              )}
            >
              {canView && <DocumentAssignmentViewDropdownItem assignment={documentAssignment} />}

              {canSign && (
                <Dropdown.Item
                  onClick={onSignDocumentClick}
                >
                  Sign document
                </Dropdown.Item>
              )}

              {canCancel && (
                <>
                  <Dropdown.Divider />
                  <Dropdown.Item
                    className="text-danger"
                    onClick={async () => {
                      try {
                        const { data } = await axios.post(cancelDocumentAssignmentApiUrl(
                          orgAlias, documentAssignment.id,
                        ));
                        onUpdated(data);
                        toastr.success('Well Done!', 'Successfully cancelled that confirmation');
                      } catch (err) {
                        toastr.error('Oh Snap!', err.response?.data?._error || err.message);
                      }
                    }}
                  >
                    Cancel
                  </Dropdown.Item>
                </>
              )}
            </TDDropdown>
          </div>
        </div>
      </div>
    </div>
  );
};

DocumentAssignmentsListItem.propTypes = {
  documentAssignment: PropTypes.object.isRequired,
  hasOrgAccess: PropTypes.func.isRequired,
  history: routerHistorySpec.isRequired,
  location: PropTypes.object.isRequired,
  match: routerMatchSpec.isRequired,
  onUpdated: PropTypes.func,
};

DocumentAssignmentsListItem.defaultProps = {
  onUpdated: () => null,
};

const mapStateToProps = state => ({
  hasOrgAccess: getHasOrgAccess(state),
});

const DocumentAssignmentsListItemConncted = connect(mapStateToProps)(DocumentAssignmentsListItem);


export default withRouter(DocumentAssignmentsListItemConncted);
