import { pick } from 'lodash';
import PropTypes from 'prop-types';
import React from 'react';
import { useSelector } from 'react-redux';
import { Link, useLocation, useParams } from 'react-router-dom';
import queryString from 'query-string';

import AutoSuggestField from 'core/assets/js/components/FinalFormFields/AutoSuggestField.jsx';
import SelectableListFilterField from 'core/assets/js/components/FinalFormFilterFields/SelectableListFilterField.jsx';
import GridViewToggle from 'core/assets/js/components/GridViewToggle.jsx';
import ProfilePic from 'core/assets/js/components/ProfilePic.jsx';
import SearchFinalForm from 'core/assets/js/components/SearchFinalForm.jsx';
import PeopleListSkeleton from 'core/assets/js/components/Skeleton/PeopleListSkeleton.jsx';
import StatusTag from 'core/assets/js/components/StatusTag.jsx';
import Table from 'core/assets/js/components/Table.jsx';
import TDDropChevronButton from 'core/assets/js/components/TDDropChevronButton.jsx';
import TDPagination from 'core/assets/js/components/TDPagination.jsx';
import withFilters from 'core/assets/js/components/withFilters.jsx';
import {
  IMG_SIZE, ORDERING_DIRECTION, POPPER_PLACEMENT, USER_TYPE, VIEW_TYPES,
} from 'core/assets/js/constants';
import { fetchDataHook } from 'core/assets/js/ducks/hooks';
import { getPeopleListViewType } from 'core/assets/js/ducks/settings';
import PeopleGridListSkeleton from 'people/assets/js/components/skeletons/PeopleGridListSkeleton';
import { orgUserProfileUrl } from 'people/urls';
import TaskAssignmentActions from 'projects/assets/js/components/TaskAssignmentActions.jsx';
import TaskAssignmentModals from 'projects/assets/js/components/TaskAssignmentModals.jsx';
import {
  TASK_ASSIGNMENT_STATUS, TASK_ASSIGNMENT_STATUS_CLASS, TASK_ASSIGNMENT_STATUS_LABEL,
} from 'projects/assets/js/constants';
import { projectTaskSpec, taskAssignmentSpec } from 'projects/assets/js/lib/objectSpecs';
import { projectFetchTaskAssignmentsPaginatedApiUrl } from 'projects/urls';
import RateAmount from 'rates/assets/js/components/RateAmount.jsx';

const ActionsDropDownComponent = ({ isLast, item, listViewType, task }) => {
  const dropDownClassNames = ['team-grid-list-item__actions-drop-down'];
  if (listViewType === VIEW_TYPES.GRID) {
    dropDownClassNames.push('team-grid-list-item__actions-drop-down--fixed');
  }
  return (
    <TDDropChevronButton
      className={dropDownClassNames.join(' ')}
      data-testid="task-assignment-card-actions"
      menuPlacement={isLast ? POPPER_PLACEMENT.TOP_END : POPPER_PLACEMENT.BOTTOM_END}
      useDropButton
    >
      <TaskAssignmentActions
        assignment={item}
        task={task}
        taskAllowedActions={task.accessControl}
      />
    </TDDropChevronButton>
  );
};

ActionsDropDownComponent.propTypes = {
  isLast: PropTypes.bool.isRequired,
  item: taskAssignmentSpec.isRequired,
  listViewType: PropTypes.oneOf(Object.values(VIEW_TYPES)).isRequired,
  rootComponentName: PropTypes.string.isRequired,
  task: projectTaskSpec.isRequired,
};

const Status = ({ status }) => (
  <StatusTag
    label={TASK_ASSIGNMENT_STATUS_LABEL[status]}
    statusClass={TASK_ASSIGNMENT_STATUS_CLASS[
      status === TASK_ASSIGNMENT_STATUS.PENDING_RATE_AGREEMENT
        ? TASK_ASSIGNMENT_STATUS.PENDING
        : status
    ]}
  />
);

Status.propTypes = {
  status: PropTypes.oneOf(Object.values(TASK_ASSIGNMENT_STATUS)).isRequired,
};

const TaskAssigneesTab = ({ filtersOpen, onFiltersToggle, parentComponentName, task }) => {
  const params = useParams();
  const location = useLocation();

  const listViewType = useSelector(getPeopleListViewType);
  const isGrid = listViewType === VIEW_TYPES.GRID;

  const componentName = TaskAssigneesTab.GetComponentName(params);
  const { hasLoaded, isLoading, items, pagination } = fetchDataHook({
    changesArray: [listViewType],
    componentName,
    duck: 'list',
    url: projectFetchTaskAssignmentsPaginatedApiUrl(params.orgAlias, params.id, params.taskId),
    queryStringParams: { pageSize: isGrid ? 12 : 10 },
  });

  const loading = isLoading || !hasLoaded;

  const hasAssignees = items.length > 0;

  return (
    <>
      <div className="row">
        <SearchFinalForm
          extraSearchElement={(
            <div className="d-flex align-items-center ml-auto">
              <div className="d-none d-xl-flex flex-nowrap align-items-center">
                <GridViewToggle />
              </div>
            </div>
          )}
          name="taskAssignees"
          initialValues={pick(
            queryString.parse(location.search), ['kw', 'ordering', 'status'],
          )}
          searchSpec={{
            defaultOrdering: { direction: ORDERING_DIRECTION.ASC, sortBy: 'lastName' },
            filters: [{
              fieldComponent: SelectableListFilterField,
              label: 'Status',
              multiple: true,
              options: Object.values(TASK_ASSIGNMENT_STATUS).map(value => ({
                text: TASK_ASSIGNMENT_STATUS_LABEL[value], value,
              })),
              paramName: 'status',
            }],
            orderingOptions: [
              { text: 'Last Name', value: 'lastName' }, { text: 'Status', value: 'status' },
            ],
            searchTerm: {
              component: AutoSuggestField, paramName: 'kw', placeholder: 'Search assignees',
            },
          }}
          onFiltersToggle={onFiltersToggle}
          filtersOpen={filtersOpen}
        />
      </div>
      {loading && (isGrid ? <PeopleGridListSkeleton /> : <PeopleListSkeleton />)}
      {!loading && !filtersOpen && (
        <>
          {isGrid && (
            <>
              {hasAssignees && (
                <ul className="task-assignees-grid-view">
                  {items.map(assignment => (
                    <li className="team-grid-list-item text-center h-100" key={assignment.id}>
                      <div
                        className={(
                          'team-grid-list-item__container d-flex flex-column bg-white bg-white p-5'
                        )}
                      >
                        <ActionsDropDownComponent
                          isLast={false}
                          item={assignment}
                          listViewType={listViewType}
                          rootComponentName={componentName}
                          task={task}
                        />
                        <Link
                          className="d-flex"
                          to={orgUserProfileUrl(
                            params.orgAlias,
                            USER_TYPE.PROVIDER,
                            assignment.userId,
                          )}
                          title={assignment.user.profile.name}
                        >
                          <ProfilePic
                            alt={assignment.user.profile.name}
                            url={assignment.user.profile.avatar}
                            size={[IMG_SIZE.SMALL, IMG_SIZE.SMALL]}
                          />
                          <div className="d-flex flex-column align-items-start ml-5">
                            <div>{assignment.user.profile.name}</div>
                            <div className="d-flex flex-row discreet align-items-center mb-2">
                              <div>{assignment.user.profile.jobTitle}</div>
                              <div className="mx-3">&#x2022;</div>
                              <div>
                                <RateAmount
                                  amount={assignment.rate}
                                  unit={assignment.rate_unit}
                                  symbol={assignment.currencySymbol}
                                />
                              </div>
                            </div>
                            <Status status={assignment.status} />
                          </div>
                        </Link>
                      </div>
                    </li>
                  ))}
                </ul>
              )}
              {!hasAssignees && <div className="w-100 text-center mt-5">No assignees</div>}
            </>
          )}
          {!isGrid && (
            <Table
              cols={[
                {
                  dataFormat: (id, { user: { profile: { avatar, name } }, userId }) => (
                    <div className="d-flex align-items-center">
                      <ProfilePic
                        alt={name}
                        className="mr-3"
                        url={avatar}
                        size={[IMG_SIZE.SMALL, IMG_SIZE.SMALL]}
                      />
                      <div className="d-flex flex-column align-items-start">
                        <Link
                          className="text-dark font-weight-bolder"
                          to={orgUserProfileUrl(
                            params.orgAlias,
                            USER_TYPE.PROVIDER,
                            userId,
                          )}
                        >
                          <span className="text-break">{name}</span>
                        </Link>
                      </div>
                    </div>
                  ),
                  key: 'id',
                  label: 'Name',
                },
                {
                  dataFormat: (id, { user: { profile: { jobTitle } } }) => jobTitle,
                  key: 'id',
                  label: 'Title',
                },
                {
                  dataFormat: (rate, { currencySymbol, rate_unit: rateUnit }) => (
                    <RateAmount amount={rate} unit={rateUnit} symbol={currencySymbol} />
                  ),
                  key: 'rate',
                  label: 'Rate',
                },
                {
                  dataFormat: status => <Status status={status} />,
                  key: 'status',
                  label: 'Status',
                },
                {
                  dataFormat: (id, { user: { profile: { skills } } }) => (
                    skills.map(skill => skill.label).join(', ')
                  ),
                  key: 'id',
                  label: 'Skills',
                },
                {
                  dataFormat: (id, item, formatExtraData, rowIdx) => (
                    <ActionsDropDownComponent
                      isLast={rowIdx === items.length - 1}
                      item={item}
                      listViewType={listViewType}
                      rootComponentName={componentName}
                      task={task}
                    />
                  ),
                  key: 'id',
                  label: '',
                  width: '68px',
                },
              ]}
              containerClass="task-assignees-table"
              items={items}
              responsive={false}
            />
          )}
          <TDPagination {...pagination} />
          <TaskAssignmentModals
            listViewComponentName={componentName}
            parentComponentName={parentComponentName}
            task={task}
          />
        </>
      )}
    </>
  );
};

TaskAssigneesTab.GetComponentName = () => 'TaskAssigneesTab';

TaskAssigneesTab.propTypes = {
  filtersOpen: PropTypes.bool.isRequired,
  onFiltersToggle: PropTypes.func.isRequired,
  parentComponentName: PropTypes.string.isRequired,
  task: projectTaskSpec.isRequired,
};

export default withFilters(TaskAssigneesTab);
