/* globals File */
import axios from 'axios';
import { pick } from 'lodash';
import React, { useEffect, useState } from 'react';
import { toastr } from 'react-redux-toastr';
import { useHistory, useParams } from 'react-router-dom';

import { downloadFile } from 'accounts/assets/js/lib/helpers';
import ProjectCardSkeleton from 'core/assets/js/components/Skeleton/ProjectCardSkeleton.jsx';
import { fetchDataHook } from 'core/assets/js/ducks/hooks';
import ContentHeader from 'core/assets/js/layout/placeholder/ContentHeader.jsx';
import { NotFound } from 'core/assets/js/NotFoundView.jsx';
import { extractCustomFieldSetup } from 'interviews/assets/js/lib/utils';
import ProjectForm from 'projects/assets/js/components/ProjectForm.jsx';
import { PROJECT_VISIBILITY } from 'projects/assets/js/constants';
import { projectViewApiUrl, projectViewUrl } from 'projects/urls';
import { customFieldsTemplateListApiUrlAll } from 'interviews/urls';
import { FIELD_ENTITY_TYPE } from 'interviews/assets/js/constants';

const ProjectEdit = () => {
  const history = useHistory();
  const { orgAlias, id } = useParams();
  const [loadedFiles, setLoadedFiles] = useState(false);
  const [attachmentFiles, setAttachmentFiles] = useState([]);

  const componentName = ProjectEdit.GetComponentName();
  const { hasLoaded: hasLoadedProject, item: { project } = {} } = fetchDataHook({
    componentName: `${componentName}Project`,
    url: projectViewApiUrl(orgAlias, id),
  });
  const {
    hasLoaded: hasLoadedCustomFieldTemplates,
    item: customFieldTemplates,
  } = fetchDataHook({
    componentName: `${componentName}CustomFieldTemplates`,
    url: customFieldsTemplateListApiUrlAll({
      entityType: FIELD_ENTITY_TYPE.PROJECT,
      orgAlias,
    }),
  });

  const finishedLoading = hasLoadedProject && hasLoadedCustomFieldTemplates && loadedFiles;

  const loadFiles = async attachments => {
    if (attachments.length === 0) {
      return;
    }
    try {
      const newAttachmentFiles = [];
      await Promise.all(attachments.map(async attachment => {
        if (/^\d+$/.test(attachment.toString())) {
          // This is the id for one of our files DB records
          const file = await downloadFile(attachment);
          newAttachmentFiles.push(file);
          return;
        }
        if (
          typeof attachment?.url === 'string'
          && typeof attachment.filename === 'string'
          && typeof attachment.mimetype === 'string'
        ) {
          // This is a filestack object
          const { data: fileBlob } = await axios.get(attachment.url, { responseType: 'blob' });
          newAttachmentFiles.push(new File(
            [fileBlob], attachment.filename, { type: attachment.mimetype },
          ));
        }
      }));
      setAttachmentFiles(newAttachmentFiles);
    } catch (e) {
      toastr.error('Oh Snap!', e._error || e.message);
    }
  };

  useEffect(() => {
    if (project) {
      loadFiles(project.attachments)
        .then(() => {
          setLoadedFiles(true);
        });
    }
  }, [project]);

  const {
    initialValues: customFieldInitialValues,
    selectedCustomFields,
  } = extractCustomFieldSetup({ answers: project?.answers });

  const canEditProject = project?.allowedActions?.canEditProject;

  const initialValues = {
    ...pick(
      project || {},
      'brief',
      'clients',
      'currency',
      'deadline',
      'external_project_id',
      'id',
      'started_at',
      'tags',
      'max_applicants',
      'member_management',
      'member_view',
      'skills',
      'title',
      'visibility',
    ),
    ...customFieldInitialValues,
    attachments: attachmentFiles,
  };
  if (project?.visibility === PROJECT_VISIBILITY.ORG_ONLY) {
    Object.assign(initialValues, {
      rate_guide_maximum: project?.rateGuideMaximum,
      rate_guide_minimum: project?.rateGuideMinimum,
      rate_guide_unit: project?.rateGuideUnit,
    });
    if (
      Array.isArray(project?.opportunityUserOrGroups) && project.opportunityUserOrGroups.length > 0
    ) {
      initialValues.usersOrGroups = project.opportunityUserOrGroups.map(e => {
        if (e.userId) {
          return {
            data: { user: e.user }, isUserCard: true, label: e.user?.profile?.name, value: e.userId,
          };
        }
        return {
          data: pick(e.userGroup, 'membersCount', 'name'),
          isGroup: true,
          label: e.userGroup?.name,
          value: e.userGroupId,
        };
      });
    }
  }

  return (
    <>
      <ContentHeader breadcrumbs={[{ title: 'Edit Project', url: null }]} />
      <div className="page page--project-edit">
        <div className="container">
          {finishedLoading && (
            <>
              {canEditProject && (
                <ProjectForm
                  allowTemplateSelection={false}
                  customFieldTemplates={customFieldTemplates}
                  customFields={selectedCustomFields}
                  enableBudgetAllocation={false}
                  enableEditingMemberSettings={project?.allowedActions?.isOwner}
                  initialValues={initialValues}
                  onSubmitSuccess={() => {
                    history.push(projectViewUrl(orgAlias, id));
                  }}
                  orgAlias={orgAlias}
                  projectId={id}
                  submitLabel="Update Project"
                />
              )}
              {!canEditProject && (
                <NotFound errMsg="You don't have access to this page" displayInline />
              )}
            </>
          )}
          {!finishedLoading && <ProjectCardSkeleton />}
        </div>
      </div>
    </>
  );
};

ProjectEdit.GetComponentName = () => 'ProjectEdit';

export default ProjectEdit;
